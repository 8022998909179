<template>
    <div>
        <Head title="全量信息" out="Recognition" />
        <infoImage @shibieInfo="shibieInfo" @shibieHou="shibieHou" @carRen="carRen" ref="imgRef" />
        <SubstanceInfo  :info="info" :VinInfo="VinInfo" :carInfo="carInfo" ref="infoRef" />
        <div class="div_btn">
            <van-button type="info"  size="large" @click="submit">提交</van-button>
        </div>
        <van-popup
            v-model="CarInfoShow"
            closeable
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',width:'100%'}">
            <div class="title_car">车辆列表</div>
            <ObtainCarInfo ref="ObtainRef" :repetition="repetition" @colosepopup="colosepopup" :vin="vinStr" @comeWei="comeWei" @upDate="upDate" />
        </van-popup>
    </div>
</template>

<script>
import Head from '@/components/Head'
import infoImage from './infoImage.vue'
import SubstanceInfo from './SubstanceInfo.vue'
import { is_post } from "../../Http/api.js";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { Dialog } from 'vant'
import ObtainCarInfo from '@/components/ObtainCarInfo'
import {PlateNum} from '@/api/carInfo'
    export default {
         components: {
            Head,infoImage,SubstanceInfo,ObtainCarInfo
        },
        data() {
            return {
               info:{},
               VinInfo:{},
               carInfo:{},
               CarInfoShow:false,
               repetition:[],
               vinStr:''
            }
        },
       
        methods: {
            shibieInfo(data){
                this.info = data
            },
            shibieHou(data){
                this.VinInfo = data
            },
            carRen(data){
                this.carInfo = data
            },
             //取消
            colosepopup(){
                 this.CarInfoShow = false
            },
            //直接提交
            comeWei(){
                this.CarInfoShow = false
                this.onSubmit()
            },
            //更新
            upDate(e){
                this.$refs.infoRef.isData.identificationCode = e[0]
                this.CarInfoShow = false
                this.onSubmit()
            },
            async submit(){
                if(this.$refs.infoRef.isData.number && this.$refs.infoRef.isData.identificationCode){
                    
                    const {data} = await PlateNum({value:this.$refs.infoRef.isData.number}).then(res=>res)
                    let arr =  data.data
                    this.vinStr = this.$refs.infoRef.isData.identificationCode
                    if(arr.length==0){
                        this.onSubmit()
                    }else if(arr.length==1){
                        if(arr[0].carNumberPlate == this.$refs.infoRef.isData.number && arr[0].vin == this.$refs.infoRef.isData.identificationCode){
                            this.onSubmit()
                        }else{
                            this.repetition = arr
                            this.CarInfoShow = true
                            this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                            })
                        }
                    }else if(arr.length > 1){
                        this.repetition = arr
                        this.CarInfoShow = true
                        this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                        })
                    }
                }else {
                    this.$toast.fail('请填写车牌或跟车架号。')
                }
            },
            onSubmit(){
                 Dialog.confirm({
                    title: '提示',
                    message:
                        `<p>请再次核对车牌号及车架号是否正正确</p><p>车牌号:${this.$refs.infoRef.isData.number}</p><p>车架号:<br>${this.$refs.infoRef.isData.identificationCode}</p>`,
                    }) .then(async() => {
                         try{
                            let arr = {}
                            let arr1 = {}
                            if(this.$refs.infoRef.tableData.length!=0){
                                    this.$refs.infoRef.tableData.forEach(i=>{
                                        let obj = {
                                            [i.id]:{
                                                price:i.price,
                                                num:i.num
                                            }
                                        }
                                        arr = {...arr,...obj}
                                    })
                                }
                                if(this.$refs.infoRef.DetetData.length!=0){
                                    this.$refs.infoRef.DetetData.forEach(i=>{
                                        let obj = {
                                            [i.id]:{
                                                price:i.price,
                                                num:i.num
                                            }
                                        }
                                        arr1 = {...arr1,...obj}
                                    })
                                }
                                
                            let obj = {
                                ...this.$refs.infoRef.isData,//行驶证正面识别信息
                                ...this.$refs.infoRef.versoData,//行驶证反面识别信息

                                reverseUrl:this.$refs.imgRef.reverseUrl || '',//行驶证反面
                                frontUrl:this.$refs.imgRef.frontUrl || '',//行驶证正面
                                carNumberPlate:this.$refs.infoRef.isData.number,//车牌号
                                vin:this.$refs.infoRef.isData.identificationCode,//VIN
                                path:this.$refs.imgRef.path || '',//车牌照片
                                vin_photos:this.$refs.imgRef.vin_photos || '',//VIN你照片
                                purchasingChannelsId:this.$refs.infoRef.purchasingChannelsId||'',//采购渠道
                                purchaseType:this.$refs.infoRef.purchaseType||'',//采购类型
                                other_photos:this.$refs.infoRef.WXimg||'',//其他照片
                                quality_inspection:arr,//质检增费
                                quality_inspection_deduction:arr1,//质检扣费
                                defined_num:this.$refs.infoRef.defined_num||'',//自定义编号
                                weight:this.$refs.infoRef.batch_average_weight||'',//过磅重量
                                weight_type:this.$refs.infoRef.batch_average_weight_type||'',//单位
                                deduction_weight:this.$refs.infoRef.suttle_batch_average_weight||'',//  扣杂重量
                                deduction_weight_type:this.$refs.infoRef.suttle_batch_average_weight_type||'',//单位
                                is_sale_of:this.$refs.infoRef.is_sale_of||'',//是否监销
                                principalUserId:this.$refs.infoRef.principalUserId,//业务员
                                defined_num_id:this.$refs.infoRef.vehicleTypeId||'',//自定义车辆类型
                                dismantle_allocation_type:this.$refs.infoRef.dismantle_allocation_type||'',
                                dismantle_allocation_remark:this.$refs.infoRef.dismantle_allocation_remark||'',
                                // name:this.$refs.infoRef.carData.type == 1 ?this.$refs.infoRef.carData.name :this.$refs.infoRef.carData.enterprise,//车主姓名
                                type:this.$refs.infoRef.carData.type||'',//类型
                                number:this.$refs.infoRef.carData.type == 1 ?this.$refs.infoRef.carData.idcard :this.$refs.infoRef.carData.credit_code,//车主号码
                                chezhu_photo:this.$refs.imgRef.carInfo_photos || '',//照片
                                destruction_photos:this.$refs.imgRef.destruction_photos||'',//销户
                                cancellation_photos:this.$refs.imgRef.cancellation_photos||'',//毁形照
                                break_beam_photos:this.$refs.imgRef.break_beam_photos||'',//毁形照
                                identification_back:this.$refs.imgRef.imageUrlF||'',//身份证反面
                            }
                            let data = encipherMent( JSON.stringify(obj))
                            let param = new URLSearchParams();
                            param.append("value",data);
                            const res = await is_post('index.php/vehicles_upload/completeVehiclesData',param).then(res=>res)
                            this.$router.push('/Recognition')
                            this.$toast.success(res.msg)
                        }
                        catch (err){}
                    }) 
                    .catch(() => {});
                
            },
           

        },
    }
</script>

<style lang="scss" scoped>
.div_btn{
    margin: 20px 0;
    text-align: center;
    padding: 10px;
}
</style>